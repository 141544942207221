define([
    "./secondaryDevicesModal/secondaryDevicesModal",
    "./monitorableModule",
    "./monitorableRouter",
    "./monitorableService",
    "./MonitorableSearchController",
    "./MonitorableEditionController",
    "./trackMonitorableModal",
    "./monitorableItemExtensionDialog",
    "./tpf/tpfModal",
    "./devicesHistory/devicesHistoryModal",
    "./devicesHistory/devicesHistoryModal.less"
], function () {
});