define(["../monitoringDeliveryOverviewModule", "text!./attachmentViewModal.html"],

    function (monitoringDeliveryOverviewModule, template) {
        "use strict";
        return monitoringDeliveryOverviewModule.service("attachmentViewModal", ["$modal", function ($modal) {
            return function (attachment) {
                return $modal.open({
                    template: template,
                    size: "xl",
                    controller: ["$scope", function ($scope) {
                        $scope.image = getBase64(attachment.file);
                        $scope.download = function () {
                            let src = getBase64(attachment.file);
                            let link = document.createElement("a")
                            link.href = src
                            link.download = `${attachment.fileName}.png`
                            link.click()
                            link.remove()
                        }

                        function getBase64(path) {
                            return `data:image/JPEG;base64,${path}`;
                        }
                    }]
                });
            };
        }]);
    });