import module from "../monitorableModule"
import template from "./devicesHistoryModal.html"


module.service("devicesHistoryModal", [ "$modal",
    function ($modal) {
        return function (monitorbaleId) {
            return $modal.open({
                template: template,
                size: "lg",
                backdrop: "static",
                controller: [ "$scope", "mbrService", "loadingService", function($scope, mbrService,loadingService) {

                    const IN_EXECUTION = "br.com.neolog.monitoring.monitorable.model.api.CompletionStatus.IN_EXECUTION";
                    const FINISHED = "br.com.neolog.monitoring.monitorable.model.api.CompletionStatus.FINISHED";
                    const CANCELLED = "br.com.neolog.monitoring.monitorable.model.api.CompletionStatus.CANCELLED";

                    $scope.devicesHistory = []

                    loadingService(mbrService.getDevicesHistory(monitorbaleId)).then((response) => {
                        $scope.devicesHistory = response;
                    });

                    $scope.getBundleByClassification = function (classification) {
                        if (!classification){
                            return "monitoring.monitorable.devices.history.modal.classification.secondary";
                        }
                        return "monitoring.monitorable.devices.history.modal.classification.primary";
                    }

                    $scope.getBundleByDeviceStatus = function (status) {
                        switch (status) {
                            case FINISHED:
                                return "monitoring.monitorable.devices.history.modal.status.finished";
                            case CANCELLED:
                                return "monitoring.monitorable.devices.history.modal.status.cancelled";
                            case IN_EXECUTION :
                                return "monitoring.monitorable.devices.history.modal.status.inExecution";
                            default :
                                return "monitoring.monitorable.devices.history.modal.status.create";
                        }
                    }

                    $scope.getDeviceStatusClass = function (status) {
                        switch (status) {
                            case FINISHED || CANCELLED:
                                return "badge badge-finalized";
                            case IN_EXECUTION :
                                return "badge badge-execution";
                            default :
                                return "badge badge-create";
                        }
                    }
                }]
            })
        }
    }
])