define([
    "./monitorableModule",
    "lodash",
    "text!../occurrences/OccurrencePopupTemplate.html",
    "front-end-web-commons/app/arrays/arrays",
], function (monitorableModule, _, occurrencePopupTemplate, arrays) {
    "use strict";

    return monitorableModule.controller("MonitorableEditionController", [
        "$scope",
        "$q",
        "$state",
        "$stateParams",
        "loadingService",
        "remoteExceptionHandler",
        "monitoringBasketService",
        "monitorable",
        "monitoringService",
        "monitorableService",
        "transitionService",
        "completionService",
        "CompletionStatus",
        "trackingService",
        "markerImage",
        "markerService",
        "alterDriverModal",
        "monitoringVelocityConfigurationModal",
        "DataFetcherType",
        "mbrService",
        "messagesModal",
        "tpfModal",
        function ($scope,
                  $q,
                  $state,
                  $stateParams,
                  loadingService,
                  remoteExceptionHandler,
                  monitoringBasketService,
                  monitorable,
                  monitoringService,
                  monitorableService,
                  transitionService,
                  completionService,
                  CompletionStatus,
                  trackingService,
                  markerImage,
                  markerService,
                  alterDriverModal,
                  monitoringVelocityConfigurationModal,
                  DataFetcherType,
                  mbrService,
                  messagesModal,
                  tpfModal) {

            $scope.entity = prepareData(monitorable);
            $scope.transitions = extractTransitions($scope.entity);
            $scope.isRoot = monitorable.root;
            $scope.monitorableStatus = {};
            $scope.occurrencesDetails = {};
            $scope.rootMonitorablePresentationTreeNode = {};
            $scope.refresh = reloadEntityWithLoadingService;
            $scope.monitoringBasketService = monitoringBasketService;
            $scope.map = {
                api: null,
                routes: [],
                realizedRoute: [],
                markers: [],
                nonClusteredMarkers: [],
                refresh: reloadEntity,
                registerRoutesApi: function (api) {
                    $scope.map.api = api;
                }
            };
            $scope.tracking = {
                truckDevices: [],
                lastSignal: null,
                shouldDisplayRealizedPath: true,
                trackingMonitorablesByClassification: {
                    primary: {
                        trackingMonitorables: [],
                        isAllSelected: false,
                    },
                    secondary: {
                        trackingMonitorables: [],
                    },
                    isPrimaryView: true,
                }
            };

            $scope.monitorableTemperatureTrackingDetails = [];

            function TemperatureTrackingDetails(providerId, sensorIdentifier) {
                this.providerId = providerId;
                this.sensorIdentifier = sensorIdentifier;
                this.temperature = "N/A";
            }

            TemperatureTrackingDetails.prototype = {
                setTemperature(temperature) {
                    this.temperature = temperature;
                }
            };

            var tabParam = $stateParams.tab || "general";
            $scope.tabs = {
                "general": tabParam === "general",
                "details": tabParam === "details",
                "hierarchy": tabParam === "hierarchy",
                "transitions": tabParam === "transitions",
                "occurrences": tabParam === "occurrences"
            };

            init();

            $scope.back = function () {
                return $state.go("monitoring.monitoringMonitorableSearch");
            };

            $scope.isEnabled = function () {
                return completionService.isActive($scope.entity.completion.status);
            };

            $scope.isInExecution = function () {
                if ($scope.isRoot) {
                    return CompletionStatus.IN_EXECUTION === $scope.entity.completion.status;
                }
                return !$scope.isRoot;
            };

            $scope.hasPermission = function () {
                return !$scope.isRoot
            };

            $scope.startMonitorable = function () {
                return monitorableService.startMonitorables([$scope.entity.id])
                    .then(reloadEntityWithLoadingService);
            };

            $scope.updateDriver = function () {
                return alterDriverModal($scope.entity)
                    .then(reloadEntityWithLoadingService);
            };

            $scope.editTripConfiguration = function () {
                return monitoringVelocityConfigurationModal($scope.entity)
                    .then(reloadEntityWithLoadingService);
            };

            $scope.finishMonitorable = function () {
                return monitorableService.finishMonitorables([$scope.entity.id])
                    .then(reloadEntityWithLoadingService);
            };

            $scope.cancelMonitorable = function () {
                return monitorableService.cancelMonitorables([$scope.entity.id])
                    .then(reloadEntityWithLoadingService);
            };

            $scope.serviceClick = function (service) {
                if (!service.locality) {
                    return;
                }
                $scope.map.api.centerMapOnCoords(service.locality);
            };

            $scope.hasCurrentTransitionExecuted = function (monitorableStatus) {
                if (monitorableStatus.currentTransition !== undefined && monitorableStatus.lastTransitionExecuted) {
                    return monitorableStatus.currentTransition.name === monitorableStatus.lastTransitionExecuted.name
                        || monitorableStatus.currentTransition.status === "br.com.neolog.monitoring.monitorable.model.api.transition.TransitionStatus.FINALIZED";
                }
            };

            $scope.inspectExtension = function (item) {
                monitorableItemExtensionDialog(item);
            };

            $scope.getTripRouteByAllPrimaryTrackingMonitorables = function (isAllSelected) {
                let trackingMonitorablesByClassification = $scope.tracking.trackingMonitorablesByClassification;
                let primary = trackingMonitorablesByClassification.primary;
                let secondary = trackingMonitorablesByClassification.secondary;
                
                setIsPrimaryView(true);
                setAllTrackingMonitorablesByClassification(primary.trackingMonitorables, isAllSelected);
                setAllTrackingMonitorablesByClassification(secondary.trackingMonitorables, false);
                loadRealizedRoute(0, 150);
            };

            function setAllTrackingMonitorablesByClassification(trackingMonitorables, isAllSelected) {
                trackingMonitorables.forEach(trackingMonitorable => {
                    trackingMonitorable.isSelected = isAllSelected;
                })
            }

            function setIsPrimaryView(isPrimaryView) {
                $scope.tracking.trackingMonitorablesByClassification.isPrimaryView = isPrimaryView;
            }

            $scope.getTripRouteByPrimaryTrackingMonitorable = function () {
                let trackingMonitorablesByClassification = $scope.tracking.trackingMonitorablesByClassification;
                let primary = trackingMonitorablesByClassification.primary;
                let secondary = trackingMonitorablesByClassification.secondary;
                
                let isAllPrimaryTrackingMonitorableIsSelected = verifyAllPrimaryTrackingMonitorablesIsSelected(primary);

                setIsPrimaryView(true);
                setAllSelectedPrimary(isAllPrimaryTrackingMonitorableIsSelected);
                setAllTrackingMonitorablesByClassification(secondary.trackingMonitorables, false);
                loadRealizedRoute(0, 150);
            };

            function verifyAllPrimaryTrackingMonitorablesIsSelected(primary) {
                let trackingMonitorables = primary.trackingMonitorables;
                return trackingMonitorables.every(trackingMonitorable => {
                    return trackingMonitorable.isSelected === true;
                });
            }

            function setAllSelectedPrimary(isAllSelected) {
                let primary = $scope.tracking.trackingMonitorablesByClassification.primary;
                if (isAllSelected) {
                   return primary.isAllSelected = true;
               }
               return primary.isAllSelected = false;
            }

            $scope.getTripRouteBySecondaryTrackingMonitorable = function (currentTrackingMonitorableSecondary) {
                let trackingMonitorablesByClassification = $scope.tracking.trackingMonitorablesByClassification;
                let primary = trackingMonitorablesByClassification.primary;
                let secondary = trackingMonitorablesByClassification.secondary;
                
                setIsPrimaryView(false);
                setAllSelectedPrimary(false);
                setAllTrackingMonitorablesByClassification(primary.trackingMonitorables, false);

                secondary.trackingMonitorables.forEach(trackingMonitorable => {
                    if (currentTrackingMonitorableSecondary.deviceId !== trackingMonitorable.deviceId) {
                        trackingMonitorable.isSelected = false;
                    }
                })
                loadRealizedRoute(0, 150);
            };

            function init() {
                return $q.all([
                    loadRoute(),
                    loadEntityStatus(),
                    loadConfiguration()
                ]);
            }

            function loadEntityStatus() {
                return $q.all([
                    loadMonitorableStatus(),
                    loadOccurrencesDetails(),
                    loadRootPresentationTreeNode(),
                    loadTrackingDetails(),
                    loadOccurrenceMarkers(),
                    loadTrackingMonitorable(),
                ]);
            }

            function reloadEntityWithLoadingService() {
                return loadingService(reloadEntity());
            }

            function reloadEntity() {
                return monitoringService.findMonitorableByIdWithAllFetchers($scope.entity.id)
                    .catch(remoteExceptionHandler())
                    .then(function (monitorable) {
                        $scope.entity = monitorable;
                    })
                    .then(loadEntityStatus)
            }

            function loadMonitorableStatus() {
                return monitorableService.findMonitorableStatus($scope.entity.id)
                    .then(function (monitorableStatus) {
                        $scope.monitorableStatus = monitorableStatus;
                    });
            }

            function loadOccurrencesDetails() {
                return monitoringService.findOccurrencesDetails($scope.entity.id)
                    .then(function (occurrencesDetails) {
                        $scope.occurrencesDetails = occurrencesDetails;
                    });
            }

            function loadRootPresentationTreeNode() {
                return monitorableService.findRootMonitorablePresentationTreeById($scope.entity.id)
                    .then(function (presentationNode) {
                        $scope.rootMonitorablePresentationTreeNode = presentationNode;
                    });
            }

            function loadTrackingDetails() {
                if (!!$scope.entity.trackingDetails) {
                    var lastPosition = $scope.entity.trackingDetails.lastPosition;
                    if (lastPosition.latitude !== 0.0 && lastPosition.longitude !== 0.0) {
                        $scope.tracking.lastSignal = $scope.entity.trackingDetails.lastPosition;
                    }
                }
                drawTruckMarker()
            }

            function loadTrackingMonitorable() {
                setDefaultTrackingMonitorablesByClassification()
                setDefaultTruckDevices()
                mbrService.getUniqueTrackingMonitorable($scope.entity.id).then(function (trackingMonitorables) {
                    $scope.tracking.trackingMonitorables = trackingMonitorables;
                    $scope.trackingMonitorablePromiseResolved = true;
                    setTrackingMonitorablesByClassification($scope.tracking.trackingMonitorables);
                    loadRealizedRoute(0, 150);
                });
            }

            function setDefaultTrackingMonitorablesByClassification() {
                $scope.tracking.trackingMonitorablesByClassification = {
                    primary: {
                        trackingMonitorables: [],
                        isAllSelected: false,
                    },
                    secondary: {
                        trackingMonitorables: [],
                    },
                    isPrimaryView: true,
                }
            }

            function setDefaultTruckDevices() {
                $scope.tracking.truckDevices = [];
            }

            function setTrackingMonitorablesByClassification(trackingMonitorables){
                if (!trackingMonitorables){
                    return;
                }
                let tracking = $scope.tracking;
                let trackingMonitorablesByClassification = tracking.trackingMonitorablesByClassification;
                trackingMonitorables.forEach(function (trackingMonitorable) {
                    let isPrimary = trackingMonitorable.primary;
                    setSelectedTrackingMonitorable(isPrimary, trackingMonitorable)
                    setTruckDevices(trackingMonitorable);
                    if (isPrimary){
                        trackingMonitorablesByClassification.primary.trackingMonitorables.push(trackingMonitorable);
                        return;
                    }
                    trackingMonitorablesByClassification.secondary.trackingMonitorables.push(trackingMonitorable);
                })
                let hasOnlyOneRecord = 1;
                if (trackingMonitorablesByClassification.primary.trackingMonitorables.length === hasOnlyOneRecord) {
                    setAllSelectedPrimary(true);
                }
            }

            function setSelectedTrackingMonitorable(isPrimary, trackingMonitorable){
               if (isPrimary && $scope.isTrackingMonitorableInExecution(trackingMonitorable) ) {
                   return trackingMonitorable.isSelected = true;
               }
                return trackingMonitorable.isSelected = false;
            }

            $scope.isTrackingMonitorableInExecution = function (trackingMonitorable) {
                return !trackingMonitorable.finished;
            }

            function setTruckDevices(trackingMonitorable) {
                $scope.tracking.truckDevices.push( {
                    providerId: trackingMonitorable.providerId,
                    deviceId: trackingMonitorable.deviceId
                });
            }

            function drawTruckMarker() {
                if (!$scope.tracking.lastSignal) {
                    return;
                }
                var iconUrl = markerImage.getIcon();
                $scope.map.nonClusteredMarkers = [markerService.createMarker($scope.entity.id, $scope.entity.sourceId, $scope.tracking.lastSignal, iconUrl)];
            }

            function loadRealizedRoute(firstResult, maxResult) {
                var currentRealizedRoute = [];
                let selectedTrackingMonitorableIds = getSelectedTrackingMonitorableIds();
                return recursivelyLoadRealizedRoute(currentRealizedRoute, firstResult, maxResult, selectedTrackingMonitorableIds)
                    .then(function (realizedRoute) {
                        $scope.map.realizedRoute = realizedRoute;
                    });
            }

            function getSelectedTrackingMonitorableIds() {
                let trackingMonitorablesByClassification = $scope.tracking.trackingMonitorablesByClassification;
                if (trackingMonitorablesByClassification.isPrimaryView){
                    return getSelectedTrackingMonitorableIdsByClassification(trackingMonitorablesByClassification.primary.trackingMonitorables);
                }
                return getSelectedTrackingMonitorableIdsByClassification(trackingMonitorablesByClassification.secondary.trackingMonitorables);
            }

            function getSelectedTrackingMonitorableIdsByClassification(trackingMonitorables) {
                let selectedTrackingDeviceIds = []
                if(!trackingMonitorables) {
                    return selectedTrackingDeviceIds;
                }
                trackingMonitorables.forEach(function (trackingMonitorable) {
                    if (trackingMonitorable.isSelected){
                        trackingMonitorable.trackingMonitorableIds.forEach(trackingMonitorableId => {
                            selectedTrackingDeviceIds.push(trackingMonitorableId);
                        })
                    }
                })
                return selectedTrackingDeviceIds;
            }

            function recursivelyLoadRealizedRoute(currentRealizedRoute, firstResult, maxResult, selectedTrackingMonitorableIds) {
                return loadingService(monitoringService.getRoutePathOfTripBySelectedTrackingMonitorableIds(monitorable.id, firstResult, maxResult, selectedTrackingMonitorableIds))
                    .then(function (realizedRoutes) {
                        if (realizedRoutes.length === 0) {
                            return currentRealizedRoute;
                        }
                        if (currentRealizedRoute.length === 0) {
                            currentRealizedRoute = realizedRoutes;
                        } else {
                            realizedRoutes.forEach((routeList, index) => {
                                if (!currentRealizedRoute[index]) {
                                    currentRealizedRoute[index] = [];
                                }
                                arrays.addAll(currentRealizedRoute[index].path, routeList.path);
                            });
                        }
                        firstResult += 1;
                        return recursivelyLoadRealizedRoute(currentRealizedRoute, firstResult, maxResult, selectedTrackingMonitorableIds)
                    });
            }

            function loadRoute() {
                return monitoringService.getPlannedRoutePathOfTrip(monitorable.id)
                    .then(function (routes) {
                        $scope.map.routes = routes;
                        $scope.polygons = routes.flatMap(function (route) {
                            return route.stops.flatMap(function (stop) {
                                return stop.polygons;
                            });
                        });
                    });
            }

            function loadOccurrenceMarkers() {
                $scope.map.markers = [];
                monitoringService.findMonitorableById(monitorable.id, [DataFetcherType.CHILDREN, DataFetcherType.OCCURRENCE])
                    .then(function (monitorableWithChildren) {
                        return _.flattenDeep(_.map([monitorableWithChildren], allOccurrences))
                            .filter(function (occurrence) {
                                return !!occurrence.where;
                            })
                            .map(function (occurrence) {
                                return markerService.createMarker(
                                    $scope.entity.sourceId,
                                    occurrence.id,
                                    occurrence.where,
                                    occurrence.status.presentationInfo ? occurrence.status.presentationInfo.mapIconUrl : "images/warning.png",
                                    {
                                        template: occurrencePopupTemplate,
                                        templateParameter: {
                                            currentDriver: $scope.entity.currentDriver,
                                            monitorableCode: $scope.entity.sourceId,
                                            occurrence: occurrence
                                        }
                                    });
                            });
                    }).then(function (markers) {
                    $scope.map.markers = markers;
                });
            }

            function loadConfiguration() {
                return $q.all([
                    loadMonitorableVelocities(),
                    loadSensorsConfiguration()
                ]);
            }

            $scope.sensorsConfiguration = [];

            function loadSensorsConfiguration() {
                return monitoringService.getTemperatureSensorConfigurations($scope.entity.id)
                    .then(function (providerSensorConfigurationMap) {
                        let providers = Object.keys(providerSensorConfigurationMap);
                        for (let provider of providers) {
                            providerSensorConfigurationMap[provider].forEach(function (sensorConfiguration) {
                                $scope.sensorsConfiguration.push(sensorConfiguration);
                                $scope.monitorableTemperatureTrackingDetails.push(new TemperatureTrackingDetails(sensorConfiguration.providerId,
                                    sensorConfiguration.identifier));
                            })
                        }
                        sortTemperatureTrackingDetails($scope.monitorableTemperatureTrackingDetails);
                    });
            }

            function loadMonitorableVelocities() {
                return monitoringService.getTripConfiguration($scope.entity.id)
                    .then(function (configuration) {
                        $scope.monitorableConfiguration = {
                            maxVelocity: configuration.maxVelocity,
                            minVelocity: configuration.minVelocity,
                        }
                    });
            }

            $scope.getSensorsConfiguration = (providerId) => {
                return $scope.sensorsConfiguration.filter(s => s.providerId === providerId);
            };

            function allOccurrences(node) {
                if (node.children && node.children.length > 0) {
                    return node.occurrences.concat(_.flattenDeep(_.map(node.children, allOccurrences)))
                } else {
                    return node.occurrences
                }
            }

            function prepareData(monitorable) {
                monitorable['lastOccurrence'] = getLastOccurrence(monitorable);
                monitorable['nextTransition'] = getNextTransition(monitorable);
                monitorable['lastExecutedTransition'] = getLastExecutedTransition(monitorable);
                return monitorable;
            }

            function getLastOccurrence(monitorable) {
                if (_.isEmpty(monitorable.occurrences)) {
                    return "";
                }
                var occurrence = monitorable.occurrences[monitorable.occurrences.length - 1];
                return occurrence.category.name + occurrence.cause.name;
            }

            function getNextTransition(monitorable) {
                var transitions = extractTransitions(monitorable);
                var reversed = transitions.slice().reverse();
                if (_.isEmpty(reversed)) {
                    return "";
                }
                if (reversed[0].finalizedTimestamp > 0) {
                    return "";
                }
                reversed.forEach(function (transition) {
                    if (transition.finalizedTimestamp > 0) {
                        return reversed[reversed.indexOf(transition) - 1].name;
                    }
                });
                return transitions[0].name;
            }

            function getLastExecutedTransition(monitorable) {
                var transitions = extractTransitions(monitorable);
                var finalizedTransitions = transitions.filter(function (transition) {
                    return transition.finalizedTimestamp > 0;
                });
                if (_.isEmpty(finalizedTransitions)) {
                    return "";
                }
                finalizedTransitions = _.sortBy(finalizedTransitions, 'finalizedTimestamp');
                return finalizedTransitions[finalizedTransitions.length - 1].name;
            }

            function extractTransitions(monitorable) {
                var transitions = [];
                monitorable.transitionGroups.forEach(function (transitionGroups) {
                    transitionGroups.transitions.forEach(function (transition) {
                        transitions.push(transition);
                    })
                });
                return transitions;
            }

            $scope.getTravelledDistance = function (data) {
                if (!data) {
                    return "N/A";
                }
                return data.travelledDistance;
            };

            $scope.getOrNA = function (data) {
                if (data === "" || data == null || data === 0 || data.length === 0) {
                    return "N/A";
                }
                return data;
            };

            $scope.formatTemperature = function (temperature) {
                if (temperature === "N/A") {
                    return temperature;
                }
                return temperature + "ºC";
            };

            $scope.getTemperatureDetails = function () {
                let temperaturesDetails = extractTemperatureDetails($scope.entity);
                associateTemperatureDetailsWithSensorConfiguration(temperaturesDetails);
                return $scope.monitorableTemperatureTrackingDetails;
            };

            function extractTemperatureDetails(entity) {
                if (!(entity.trackingDetails && entity.trackingDetails.temperatureDetails)) {
                    return [];
                }
                return entity.trackingDetails.temperatureDetails;
            }

            function associateTemperatureDetailsWithSensorConfiguration(temperaturesDetails) {
                for (let temperatureDetails of temperaturesDetails) {
                    let trackingDetails = $scope.monitorableTemperatureTrackingDetails
                        .find(ttd => ttd.providerId === temperatureDetails.providerId
                            && ttd.sensorIdentifier === temperatureDetails.sensorIdentifier);
                    if (trackingDetails) {
                        trackingDetails.setTemperature(temperatureDetails.temperature);
                    }
                }
            }

            function sortTemperatureTrackingDetails(temperatureTrackingDetails) {
                temperatureTrackingDetails.sort(function (s1, s2) {
                    return s1.sensorIdentifier - s2.sensorIdentifier;
                });
                return temperatureTrackingDetails.sort(sortByProvider);
            }

            function sortByProvider(sensorConfiguration1, sensorConfiguration2) {
                if (sensorConfiguration1.providerId > sensorConfiguration2.providerId) {
                    return 1;
                }
                if (sensorConfiguration1.providerId < sensorConfiguration2.providerId) {
                    return -1;
                }
                return 0;
            }

            $scope.openTPFModal = function (invoice) {
                if (invoice.typeName === "INVOICE") {
                    tpfModal(invoice);
                }
            }
        }
    ]);
});
