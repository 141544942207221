define([
    "./monitorableModule",
    "text!./trackMonitorableModal.html",
    "front-end-web-commons/app/arrays/arrays",
], function (module, template, arrays) {
    "use strict";
    return module.service("trackMonitorableModal", [
        "$modal",
        "monitoringService",
        "mbrService",
        "trackingService",
        "CompletionStatus",
        "sensorConfigurationByDeviceModal",
        "monitoringVelocityConfigurationModal",
        "$q",
        "secondaryDevicesModal",
        "loadingService",
        "messagesModal",
        "remoteExceptionHandler",
        "devicesHistoryModal",
        function ($modal, monitoringService, mbrService, trackingService, CompletionStatus, sensorConfigurationByDeviceModal, monitoringVelocityConfigurationModal, $q, secondaryDevicesModal, loadingService, messagesModal, remoteExceptionHandler, devicesHistoryModal ) {
            return function (monitorables, shouldStart) {
                const CREATED = "br.com.neolog.monitoring.monitorable.model.api.CompletionStatus.CREATED";
                const CANCELLED = "br.com.neolog.monitoring.monitorable.model.api.CompletionStatus.CANCELLED";
                const FINISHED = "br.com.neolog.monitoring.monitorable.model.api.CompletionStatus.FINISHED";
                let _deviceByMonitorables = [];
                configureDeviceByMonitorable();

                return loadingService(trackingService.findByMonitorableIds(monitorables.map((monitorable) => monitorable.id)))
                    .catch(remoteExceptionHandler())
                    .then(function (trackingMonitorables) {
                        setDevicesByMonitorable(trackingMonitorables);

                        if (!shouldStart) {
                            return openTrackingModalConfigurations();
                        }

                        if (isAllTrackingMonitorablesConfigurated(trackingMonitorables, monitorables)) {
                            return confirmOperation("monitoring.monitorable.header.start.confirmation", monitorables)
                                .then(function () {
                                    let devicesByMonitorable = _deviceByMonitorables.flatMap(function (devicesByMonitorable) {
                                        var trackingMonitorableDtos = [];
                                        trackingMonitorableDtos.push(createTrackingMonitorableDto(devicesByMonitorable, devicesByMonitorable.truckDevice, true, devicesByMonitorable.truckPlate));
                                        devicesByMonitorable.secondaryDevices.map(function (truckDevice) {
                                            trackingMonitorableDtos.push(createTrackingMonitorableDto(devicesByMonitorable, truckDevice, false, devicesByMonitorable.truckPlate));
                                        });
                                        return trackingMonitorableDtos;
                                    });
                                    return loadingService(mbrService.updateTrackingInfoAndStart(devicesByMonitorable)).catch(remoteExceptionHandler());
                                });
                        }
                        return openTrackingModalConfigurations().then((response) => {
                            return loadingService(mbrService.updateTrackingInfoAndStart(response.devicesByMonitorableStart))
                        });
                    });

                function isAllTrackingMonitorablesConfigurated(trackingMonitorables, monitorables) {
                    let primaryTrackingMonitorables = trackingMonitorables.filter(trackingMonitorable => trackingMonitorable.primary)
                    return primaryTrackingMonitorables.length === monitorables.length;
                }

                function configureDeviceByMonitorable() {
                    DeviceByMonitorable.prototype = {
                        isStatusCreated() {
                            return this.monitorable.completion.status === CREATED;
                        },
                        areDriverAndDeviceSet() {
                            return !!(this.driver);
                        },
                        arePrimaryTruckDeviceSet() {
                            return !!(this.truckDevice);
                        },
                        setTruckDevice(truckDevice) {
                            this.truckDevice = truckDevice;
                        },
                        pushSecondaryDevice(secondaryDevice) {
                            this.secondaryDevices.push(secondaryDevice);
                        },
                        isStatusCancelledOrFinished() {
                            return this.monitorable.completion.status === CANCELLED ||
                                this.monitorable.completion.status === FINISHED;
                        }
                    };
                }

                function setDevicesByMonitorable(trackingMonitorables) {
                    monitorables.map(function (monitorable) {
                        const deviceByMonitorable = new DeviceByMonitorable(monitorable);
                        trackingMonitorables
                            .filter(trackingMonitorable => trackingMonitorable.status !== FINISHED)
                            .forEach(function (trackingMonitorable) {
                            if (monitorable.id === trackingMonitorable.monitorableId) {
                                let truckDeviceId = trackingMonitorable.truckDeviceId;
                                trackingService.findDevice(truckDeviceId).then(function (truckDevice) {
                                    if (trackingMonitorable.primary) {
                                        deviceByMonitorable.setTruckDevice(truckDevice)
                                    } else {
                                        deviceByMonitorable.pushSecondaryDevice(truckDevice);
                                    }
                                });
                            }
                        })
                        _deviceByMonitorables.push(deviceByMonitorable);
                    });
                }

                function DeviceByMonitorable(monitorable) {
                    this.monitorable = monitorable;
                    this.driver = monitorable.currentDriver;
                    this.truckDevice = null;
                    this.truckPlate = monitorable.truck ? monitorable.truck.name : '';
                    this.secondaryDevices = [];
                }

                function createTrackingMonitorableDto(devicesByMonitorable, truckDevice, primary) {
                    return {
                        monitorableId: devicesByMonitorable.monitorable.id,
                        driverId: devicesByMonitorable.driver && devicesByMonitorable.driver.id,
                        truckDeviceId: truckDevice.id,
                        primary: primary,
                        truckPlate: devicesByMonitorable.truckPlate
                    };
                }

                function confirmOperation(warningMessage, monitorables) {
                    return messagesModal.cancellable("dialog.warning", [{
                        keyBundle: warningMessage,
                        parameters: monitorables.map(function (monitorable) {
                            return monitorable.sourceId;
                        })
                    }]);
                }

                function openTrackingModalConfigurations() {
                    return $modal.open({
                        size: "lg",
                        template: template,
                        controller: ["$scope", function ($scope) {
                            $scope.onlyFreeDrivers = false;
                            $scope.shouldStart = shouldStart;
                            $scope.deviceByMonitorables = _deviceByMonitorables;

                            $scope.drivers = function (viewValue) {
                                if ($scope.onlyFreeDrivers) {
                                    return getUnassignedScopeDrivers(monitoringService.findFreeDrivers(viewValue))
                                }
                                return getUnassignedScopeDrivers(monitoringService.findDrivers(viewValue))
                            };

                            function getUnassignedScopeDrivers(findPromise) {
                                return findPromise.then(function (drivers) {
                                    if (!shouldStart || $scope.deviceByMonitorables.every(deviceByMonitorable => !deviceByMonitorable.driver)) {
                                        return drivers
                                    }
                                    return drivers.filter(function (driver) {
                                        return $scope.deviceByMonitorables.filter(deviceByMonitorable => deviceByMonitorable.driver)
                                            .some(function (deviceByMonitorable) {
                                                return deviceByMonitorable.driver.id !== driver.id
                                            })
                                    })
                                });
                            }

                            $scope.filterByDescription = function (viewValue, deviceByMonitorable) {
                                if ($scope.onlyFreeDrivers) {
                                    return getUnassignedScopeTrackingDevice(trackingService.findByDescriptionLikeWithoutMonitorable(viewValue))
                                        .then(function (truckDevices) {
                                            return arrays.minus(truckDevices, deviceByMonitorable.secondaryDevices);
                                        });
                                }
                                return getUnassignedScopeTrackingDevice(trackingService.findByDescriptionLike(viewValue))
                                    .then(function (truckDevices) {
                                        return arrays.minus(truckDevices, deviceByMonitorable.secondaryDevices);
                                    });
                            };

                            function getUnassignedScopeTrackingDevice(findPromise) {
                                return findPromise.then(function (truckDevices) {
                                    if (!shouldStart || $scope.deviceByMonitorables.every(deviceByMonitorable => !deviceByMonitorable.truckDevice)) {
                                        return truckDevices
                                    }
                                    return truckDevices.filter(function (truckDevice) {
                                        return $scope.deviceByMonitorables.filter(deviceByMonitorable => deviceByMonitorable.truckDevice)
                                            .some(function (deviceByMonitorable) {
                                                return deviceByMonitorable.truckDevice.id !== truckDevice.id
                                            })
                                    })
                                });
                            }

                            $scope.filterByDeviceId = function (viewValue, deviceByMonitorable) {
                                if ($scope.onlyFreeDrivers) {
                                    return getUnassignedScopeTrackingDevice(trackingService.findByDeviceIdLikeWithoutMonitorable(viewValue))
                                        .then(function (truckDevices) {
                                            return arrays.minus(truckDevices, deviceByMonitorable.secondaryDevices);
                                        });
                                }
                                return getUnassignedScopeTrackingDevice(trackingService.findByDeviceIdLike(viewValue))
                                    .then(function (truckDevices) {
                                        return arrays.minus(truckDevices, deviceByMonitorable.secondaryDevices);
                                    });
                            };

                            $scope.formatTag = function (tag) {
                                if (tag) {
                                    return tag.deviceId;
                                }
                            };

                            $scope.assign = function () {
                                let devicesByMonitorable = $scope.deviceByMonitorables.flatMap(function (devicesByMonitorable) {
                                    var trackingMonitorableDtos = [];
                                    trackingMonitorableDtos.push(createTrackingMonitorableDto(devicesByMonitorable, devicesByMonitorable.truckDevice, true, devicesByMonitorable.truckPlate));
                                    devicesByMonitorable.secondaryDevices.map(function (truckDevice) {
                                        trackingMonitorableDtos.push(createTrackingMonitorableDto(devicesByMonitorable, truckDevice, false, devicesByMonitorable.truckPlate ));
                                    });
                                    return trackingMonitorableDtos;
                                });

                                let devicesByMonitorableStart = $scope.deviceByMonitorables.filter(function (deviceByMonitorable) {
                                    return deviceByMonitorable.monitorable.completion.status === CompletionStatus.IN_EXECUTION;
                                }).flatMap(function (devicesByMonitorable) {
                                    var trackingMonitorableStartDtos = [];
                                    trackingMonitorableStartDtos.push(createTrackingMonitorableDto(devicesByMonitorable, devicesByMonitorable.truckDevice, true, devicesByMonitorable.truckPlate));
                                    devicesByMonitorable.secondaryDevices.map(function (truckDevice) {
                                        trackingMonitorableStartDtos.push(createTrackingMonitorableDto(devicesByMonitorable, truckDevice, false, devicesByMonitorable.truckPlate));
                                    });
                                    return trackingMonitorableStartDtos;
                                });

                                let devicesByMonitorableCreate = $scope.deviceByMonitorables.filter(function (deviceByMonitorable) {
                                    return deviceByMonitorable.monitorable.completion.status === CompletionStatus.CREATED ||
                                        deviceByMonitorable.monitorable.completion.status === CompletionStatus.IN_EXECUTION;
                                }).flatMap(function (devicesByMonitorable) {
                                    var trackingMonitorableCreateDtos = [];
                                    trackingMonitorableCreateDtos.push(createTrackingMonitorableDto(devicesByMonitorable, devicesByMonitorable.truckDevice, true, devicesByMonitorable.truckPlate));
                                    devicesByMonitorable.secondaryDevices.map(function (truckDevice) {
                                        trackingMonitorableCreateDtos.push(createTrackingMonitorableDto(devicesByMonitorable, truckDevice, false, devicesByMonitorable.truckPlate));
                                    });
                                    return trackingMonitorableCreateDtos;
                                });

                                let response = {
                                    devicesByMonitorableCreate: shouldStart ? [] : devicesByMonitorableCreate,
                                    devicesByMonitorableStart: shouldStart ? devicesByMonitorable : devicesByMonitorableStart,
                                };
                                $scope.$close(response);
                            };

                            $scope.openSecondaryDevicesModalConfiguration = function (deviceByMonitorable) {
                                return secondaryDevicesModal(deviceByMonitorable);
                            };

                            $scope.openSensorModalConfiguration = function (deviceByMonitorable) {
                                if (!$scope.existTuckDevice(deviceByMonitorable)) {
                                    return;
                                }
                                return sensorConfigurationByDeviceModal(deviceByMonitorable);
                            };

                            $scope.openDevicesHistoryModal = function (deviceByMonitorable) {
                                return devicesHistoryModal(deviceByMonitorable.monitorable.id)
                            };

                            $scope.existTuckDevice = function (deviceByMonitorable) {
                                return !!deviceByMonitorable.truckDevice;
                            }

                            $scope.openMonitoringVelelocityConfigurationModal = function (deviceByMonitorable) {
                                return monitoringVelocityConfigurationModal(deviceByMonitorable);
                            };

                            $scope.isValid = function () {
                                if (!!$scope.deviceByMonitorables) {
                                    let deviceByMonitorablesCreatedOrInExecution = $scope.deviceByMonitorables.filter(function (monitoring) {
                                        return ! monitoring.isStatusCancelledOrFinished();
                                    });

                                    return deviceByMonitorablesCreatedOrInExecution.every(function (deviceByMonitorable) {
                                        return deviceByMonitorable.areDriverAndDeviceSet() && deviceByMonitorable.arePrimaryTruckDeviceSet();
                                    });
                                }
                                return false;
                            };

                            $scope.getTitleBundle = function () {
                                if (shouldStart) {
                                    return "monitoring.track.monitorable.start.header"
                                }
                                return "monitoring.track.monitorable.config.header"
                            }

                            $scope.getButtonBundle = function () {
                                if (shouldStart) {
                                    return "monitoring.monitorable.track.modal.button.saveAndStart"
                                }
                                return "monitoring.monitorable.track.modal.button.save"
                            }

                            $scope.truckPlateChanged = function(deviceByMonitorable, newTruckPlate) {
                                deviceByMonitorable.truckPlate = newTruckPlate;
                            };
                        }
                        ]
                    }).result;
                }
            };
        }]);
});