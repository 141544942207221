define([
    "angular",
    "./monitorableModule",
], function (angular, module) {
    "use strict";

    return module.service("monitorableService", [
        "monitoringService",
        "trackingService",
        "messagesModal",
        "loadingService",
        "mbrService",
        "remoteExceptionHandler",
        "remoteMessageModal",
        "trackMonitorableModal",
        "DataFetcherType",
        "$q",
        function (monitoringService, trackingService, messagesModal, loadingService, mbrService, remoteExceptionHandler, remoteMessageModal, trackMonitorableModal, DataFetcherType, $q) {
            var self = this;

            self.findMonitorableStatus = function (monitorableId) {
                return monitoringService.findMonitorableStatus(monitorableId).catch(remoteExceptionHandler());
            };

            self.findRootMonitorablePresentationTreeById = function (monitorableId) {
                return monitoringService.findRootMonitorablePresentationTreeById(monitorableId).catch(remoteExceptionHandler());
            };

            self.updateMonitorablesConfigs = function (ids) {
                let monitorables = [];
                let deviceByMonitorablesCreate = [];
                return loadingService(monitoringService.findMonitorableByIds(ids, [DataFetcherType.DRIVER])
                    .catch(remoteExceptionHandler()))
                    .then(function (selectedMonitorables) {
                        monitorables = selectedMonitorables
                        return trackMonitorableModal(monitorables, false)
                    }).then((response) => {
                        deviceByMonitorablesCreate = response.devicesByMonitorableCreate;
                        return confirmOperation("monitoring.monitorable.header.save.without.start.confirmation", monitorables)
                            .then(function () {
                                return loadingService(mbrService.updateTrackingInfo(deviceByMonitorablesCreate)).catch(remoteExceptionHandler());
                            }).then(function (remoteMessages) {
                                return remoteMessageModal(remoteMessages);
                            });
                    });
            }

            self.startMonitorables = function (ids) {
                let _monitorables = [];
                return loadingService(monitoringService.findMonitorableByIds(ids, [DataFetcherType.DRIVER]).catch(remoteExceptionHandler()))
                    .then(function (monitorables) {
                        _monitorables = monitorables
                        var messages = validateMonitorableStatus(_monitorables);
                        if (messages.length > 0) {
                            messagesModal("dialog.warning", messages);
                            return $q.reject("Monitorables in execution");
                        }
                        return trackMonitorableModal(monitorables, true);
                        }).then(function (remoteMessages) {
                        return remoteMessageModal(remoteMessages);
                    });
            }

            self.finishMonitorables = function (ids) {
                var monitorables = [];
                return changeMonitorableStatus(
                    "monitoring.monitorable.header.finish.confirmation",
                    ids,
                    mbrService.finishMonitorables);
            };

            self.cancelMonitorables = function (ids) {
                return changeMonitorableStatus(
                    "monitoring.monitorable.header.cancel.confirmation",
                    ids,
                    mbrService.cancelMonitorables);
            };

            function changeMonitorableStatus(warningMessage, monitorableIds, alterStatusById) {
                var monitorables = [];
                return loadingService(monitoringService.findMonitorableByIds(monitorableIds)
                    .catch(remoteExceptionHandler()))
                    .then(function (_monitorables) {
                        return monitorables = _monitorables;
                    })
                    .then(function () {
                        return confirmOperation(warningMessage, monitorables);
                    }).then(function () {
                        return loadingService(alterStatusById(monitorables.map(function (monitorable) {
                            return monitorable.id;
                        }))).catch(remoteExceptionHandler());
                    }).then(function (remoteMessages) {
                        return remoteMessageModal(remoteMessages);
                    });
            }

            function validateMonitorableStatus(monitorables) {
                const CREATED = "br.com.neolog.monitoring.monitorable.model.api.CompletionStatus.CREATED";
                var messages = [];
                angular.forEach(monitorables, function (monitorable) {
                    if (monitorable.completion.status !== CREATED) {
                        let status = monitorable.completion.status.replace("br.com.neolog.monitoring.monitorable.model.api.CompletionStatus.", "");
                        messages.push({
                            keyBundle: "monitoring.monitorable.execution.modal.violation." + status,
                            parameters: monitorable
                        });
                        return messages;
                    }
                });
                return messages;
            }

            function confirmOperation(warningMessage, monitorables) {
                return messagesModal.cancellable("dialog.warning", [{
                    keyBundle: warningMessage,
                    parameters: monitorables.map(function (monitorable) {
                        return monitorable.sourceId;
                    })
                }]);
            }

            return self;
        }]);
});