define(["./monitoringDeliveryOverviewModule", "text!./monitoringDeliveryOverviewDetailsModal.html", "front-end-web-commons/app/arrays/arrays"],

    function (monitoringDeliveryOverviewModule, template, arrays) {
        "use strict";
        return monitoringDeliveryOverviewModule.service("monitoringDeliveryOverviewDetailsModal", ["$modal", "$window", "$state", "mbrService", "loadingService", "attachmentViewModal", function ($modal, $window, $state, mbrService, loadingService, attachmentViewModal) {
            return function (details, lastFinalizedTimestamp) {
                return $modal.open({
                    template: template,
                    size: "lg",
                    backdrop: "static",
                    controller: ["$scope", function ($scope) {
                        $scope.details = details;
                        $scope.lastFinalizedTimestamp = lastFinalizedTimestamp;
                        $scope.selectedInvoicesAttachments = [];
                        $scope.attachmentsByInvoiceIds = new Map();
                        $scope.selectAllInvoices = false;
                        $scope.indeterminate = false;
                        $scope.isCollapsed = true;
                        $scope.tableOptions = {
                            records: [], pagination: {
                                maxPageSizes: [10, 25],
                                maxPageSize: 10, onPaginate: function (firstResult, maxResults) {
                                    return {
                                        firstResult: firstResult,
                                        maxResults: maxResults,
                                        count: details.invoiceDetails.length,
                                        result: details.invoiceDetails.slice(firstResult, firstResult + maxResults)
                                    };
                                }
                            }, onRegisterPagination: function (api) {
                                api.seekPage(1);
                            }
                        };

                        $scope.$watchCollection("tableOptions.records", function (records) {
                            clearModal();
                        })

                        function clearModal() {
                            $scope.selectAllInvoices = false;
                            $scope.indeterminate = false;
                            $scope.tableOptions.records.forEach(function (record) {
                                record.hasToShowAttachment = false;
                            })
                            $scope.attachmentsByInvoiceIds = new Map();
                            $scope.selectedInvoicesAttachments = []
                        }

                        $scope.$watch("selectAllInvoices", function (selectAllInvoices) {
                            if (selectAllInvoices) {
                                $scope.indeterminate = false;
                            }
                        });

                        $scope.getAttachments = function () {
                            let attachments = []
                            $scope.selectedInvoicesAttachments.forEach(function (invoiceId) {
                                let attachmentsByInvoice = $scope.attachmentsByInvoiceIds.get(invoiceId)
                                if (attachmentsByInvoice) {
                                    attachments.push(...attachmentsByInvoice)
                                }
                            })
                            return attachments;
                        }

                        $scope.collapse = function () {
                            $scope.isCollapsed = !$scope.isCollapsed;
                        }

                        $scope.showAttachments = function (invoiceDetails) {
                            controllAttachmentCheckBox();
                            if (!!invoiceDetails.hasToShowAttachment) {
                                $scope.selectedInvoicesAttachments.push(invoiceDetails.invoiceId)
                                return getAllAttachmentsByInvoiceIds($scope.selectedInvoicesAttachments);
                            }
                            arrays.remove($scope.selectedInvoicesAttachments, invoiceDetails.invoiceId)
                        };

                        function controllAttachmentCheckBox() {
                            let selectedAttachments = $scope.tableOptions.records.filter(function (record) {
                                return record.hasToShowAttachment;
                            });

                            if (selectedAttachments.length === $scope.tableOptions.records.length) {
                                $scope.selectAllInvoices = true;
                                $scope.indeterminate = false
                            } else if (selectedAttachments.length === 0) {
                                $scope.selectAllInvoices = false;
                                $scope.indeterminate = false;
                            } else {
                                $scope.selectAllInvoices = false;
                                $scope.indeterminate = true;
                            }
                        }

                        $scope.showAllAttachments = function () {
                            $scope.tableOptions.records.forEach(function (record) {
                                record.hasToShowAttachment = $scope.selectAllInvoices;
                            })

                            let invoiceIds = $scope.tableOptions.records.map(function (record) {
                                return record.invoiceId;
                            })

                            if ($scope.selectAllInvoices) {
                                $scope.selectedInvoicesAttachments = invoiceIds;
                                return getAllAttachmentsByInvoiceIds(invoiceIds);
                            }
                            $scope.selectedInvoicesAttachments = [];
                        };

                        function getAllAttachmentsByInvoiceIds(invoiceIds) {
                            return loadingService(mbrService.getAttachmentsByInvoiceIds(invoiceIds))
                                .then(function (response) {
                                    const map = new Map();
                                    response.data.forEach((obj) => {
                                        map.set(obj.invoiceId, obj.attachments);
                                    });
                                    $scope.attachmentsByInvoiceIds = map;
                                })
                        }

                        $scope.getHasOccurrenceBundle = function (hasOccurence) {
                            return hasOccurence ? "monitoring.delivery.overview.details.modal.has.occurrence" : "monitoring.delivery.overview.details.modal.not.has.occurrence";
                        };

                        $scope.openOccurrenceDetails = function (invoiceId) {
                            var href = $state.href("monitoring.monitorableEdition", {
                                monitorableId: invoiceId, tab: "occurrences"
                            });
                            $window.open(href, '_blank');
                        };

                        $scope.downloadAllAttachment = function () {
                            mbrService.downloadAllMonitorablesAttachments($scope.selectedInvoicesAttachments);
                        };

                        function getBase64(path) {
                            return `data:image/JPEG;base64,${path}`;
                        }

                        $scope.getImageUrl = function (path) {
                            return getBase64(path);
                        };

                        $scope.openImage = function (attachment) {
                            return attachmentViewModal(attachment)
                        };

                    }]
                });
            };
        }]);
    });